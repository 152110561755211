<template>
  <div class="TurnDevisIntoFacture">

    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>


    <CRow>
      <CCol>
        <h1> Votre facture - {{mission.client.name}} </h1>
      </CCol>
    </CRow>
    <CCard class="mt-3">
      <CCardBody>
        <CRow>
          <CCol>
            <span> Client destinataire de votre facture : </span> <strong> {{mission.client.name}} </strong><br>
            <span v-if="mission.client.is_different_payeur"> Payeur de la facture :</span> <strong>{{mission.client.payeur_name}}</strong><br>
            <span> Mission objet de votre facture : </span> <strong> {{mission.name}}</strong>
          </CCol>
        </CRow>

        <hr>

        <addFactureComponent
          v-if="isMissionLoaded"
          :client-address="$route.params.address"
          :mission-title="$route.params.title"
          @validateFactureCreation='createFactureFromDevis'>
        </addFactureComponent>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import addFactureComponent from '@/components/factureComponents/addFactureComponent'

import { APIManagerConnected } from '@/api/APIManagerConnected'
import { APIUserConnected } from '@/api/APIUserConnected'
const apiUserConnected = new APIUserConnected()
const apiManagerConnected = new APIManagerConnected()

export default {
  name: 'TurnDevisIntoFacture',
  components: {
    addFactureComponent,
    Loading,
  },
  data: function () {
    return {
      mission: {
        id: 0,
        name: '',
        client: '',
        type: '',
        state: '',
        montant_ht: '',
        starting_date: '',
        ending_date: '',
        description: '',
        is_active: '',
        created_at: '',

        contact: {
          id: 0,
          first_name: '',
          last_name: '',
          full_name: '',
        },
        referent: {
          id: 0,
          full_name: '',
          user: {
            id: 0,
            first_name: '',
            last_name: '',
            username: ''
          }
        },
        collaborateurs: []
      },
      isMissionLoading: false,
      isMissionLoaded: false,

      addFactureInProcess: false,
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isMissionLoading || this.addFactureInProcess) {
        return true
      }
      return false
    },
  },

  created: function() {
    this.getMission()
  },

  methods: {
    // ------------- Getters --------------
    getMission () {
      this.isMissionLoading = true
      apiUserConnected.getMission(this.token, this.$route.params.idMission)
      .then((result) => {
        this.mission = result.data
        this.isMissionLoaded = true
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isMissionLoading = false
      })
    },
    createFactureFromDevis (data) {
      this.addFactureInProcess = true
      apiManagerConnected.createFactureFromDevis(this.token,
        this.$route.params.idDevis, this.$route.params.idMission, data['exerciceComptable'],
        data['title'], data['address'],
        data['delaiPaiement'], data['dateFacturation'], data['dateEcheance'],
        data['isAcompte'], data['isModalitesVirement'], data['isModalitesCb'],
        data['isModalitesCash'], data['isModalitesCheque'],
        data['messageFacture'])
      .then((result) => {
          this.$router.push('/modify-facture/' + result.data.id)
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.addFactureInProcess = false
      })
    }
  }
}
</script>
